import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Icon from "../components/Icon"

import { Link } from "gatsby"

import nissan from "../images/nissan.jpg"
import workshop from "../images/workshop.png"
import towing from "../images/towing.png"
import contact from "../images/contact.png"

const Index = ({ location }) => (
  <Layout location={location}>
    <SEO title="Home" />
    <div className="row">
      <div className="col-lg-8 index-left">
        <p>
          M.L. Wray Automotive Repairs and Towing has been operating in the
          Shoalhaven area for approximately 52 years, providing the best
          services and quality.
        </p>
      </div>
      <div className="col-lg-4 index-right">
        <img className="index-nissan" src={nissan} alt="Nissan 300SX" />
      </div>
    </div>
    <div className="row index-cards">
      <div className="col-lg-4 col-md-6">
        <div className="card">
          <img src={workshop} className="card-image" alt="Workshop Icon" />
          <div className="card-body">
            <h5 className="card-title">Workshop</h5>
            <p className="card-text">
              When you deal with the ML Wray workshop you are assured of the
              best customer service
            </p>
            <Link to="/workshop" className="btn btn-primary">
              Read More
              <Icon name="arrow-right" />
            </Link>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="card">
          <img src={towing} className="card-image" alt="Workshop Icon" />
          <div className="card-body">
            <h5 className="card-title">24/7 Towing</h5>
            <p className="card-text">
              24 Hr Emergency and Breakdown service. We use modern Tilt Tray
              Services to transport all vehicles
            </p>
            <Link to="/towing" className="btn btn-primary">
              Read More
              <Icon name="arrow-right" />
            </Link>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="card">
          <img src={contact} className="card-image" alt="Workshop Icon" />
          <div className="card-body">
            <h5 className="card-title">Contact</h5>
            <p className="card-text">
              Give us a call, send us an online enquiry or get directions. We’re
              here to help{" "}
            </p>
            <Link to="/contact" className="btn btn-primary">
              Read More
              <Icon name="arrow-right" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Index
